import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import clsx from 'clsx';
import { Script } from 'gatsby';
import React, { useCallback, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {
  autocompleteOptions,
  convertAutocompleteDataToHubspotFields,
} from '../../components/Forms/utils/autocomplete';
import PartyPopperIcon from '../../images/party-icon.svg';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import '../../styles/overrides/modal-overrides.scss';
import Image from '../../utils/OptimizedImage';
import { ModalBody } from './EvalCalculatorStyles';
import { shopTypes, states } from './helpers';

const SubmitModal = ({ formData, setFormData, onSubmit, ...props }) => {
  const [hasError, setHasError] = useState(false);
  const placesAutocomplete = useRef(null);

  const initPlacesAutocomplete = () => {
    if (window.google?.maps?.places?.Autocomplete) {
      const triggerInput = document.querySelector(
        '.modal-content [name="company"]'
      );

      placesAutocomplete.current = new window.google.maps.places.Autocomplete(
        triggerInput,
        autocompleteOptions
      );

      // Set hidden field values when a place is selected
      placesAutocomplete.current.addListener('place_changed', () => {
        const selectedPlace = placesAutocomplete.current.getPlace();
        const convertedFields = convertAutocompleteDataToHubspotFields(
          selectedPlace
        );
        const visibleFields = {};

        document.querySelectorAll('.modal-content [name]').forEach(input => {
          visibleFields[input.name] = input.value;
        });

        setFormData({
          ...visibleFields,
          ...convertedFields,
        });
      });

      // Clear hidden field values when the company name input value changes
      triggerInput.addEventListener('change', () => {
        const visibleFields = {};

        document.querySelectorAll('.modal-content [name]').forEach(input => {
          visibleFields[input.name] = input.value;
        });

        setFormData(visibleFields);
      });
    }
  };

  const handleGetStarted = useCallback(() => {
    formData.firstname &&
    formData.lastname &&
    formData.email &&
    formData.phone &&
    formData.company &&
    formData.shop_type !== 'none'
      ? onSubmit()
      : setHasError(true);
  }, [
    formData.firstname,
    formData.lastname,
    formData.email,
    formData.phone,
    formData.company,
    formData.shop_type,
    onSubmit,
  ]);

  const handleChange = useCallback(
    ({ target }) =>
      setFormData({
        ...formData,
        [target.name]: target.value,
      }),
    [formData, setFormData]
  );

  return (
    <Modal centered {...props}>
      <ModalBody>
        <Image src={PartyPopperIcon} alt="success" width="80px" height="80px" />
        <h4 className="heading">Business Valuation Completed!</h4>
        <p className="subhead">Enter your info to access your results</p>
        <div className="form-container">
          <div className="started-form">
            <form>
              <Row>
                <Col md={6}>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="firstname" className="control-label">
                      First Name
                    </Form.Label>
                    <Form.Control
                      id="firstname"
                      required
                      name="firstname"
                      type="text"
                      className={clsx(
                        'form-control',
                        hasError && !formData.firstname && 'is-invalid'
                      )}
                      placeholder="Enter First name"
                      value={formData.firstname}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="lastname" className="control-label">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      id="lastname"
                      required
                      name="lastname"
                      type="text"
                      className={clsx(
                        'form-control',
                        hasError && !formData.lastname && 'is-invalid'
                      )}
                      placeholder="Enter Last name"
                      value={formData.lastname}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="email" className="control-label">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      id="email"
                      required
                      name="email"
                      type="email"
                      className={clsx(
                        'form-control',
                        hasError && !formData.email && 'is-invalid'
                      )}
                      placeholder="Enter email address"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="phone" className="control-label">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      id="phone"
                      required
                      name="phone"
                      type="tel"
                      className={clsx(
                        'form-control',
                        hasError && !formData.phone && 'is-invalid'
                      )}
                      placeholder="Enter phone number"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="form-group required mb-3">
                <Form.Label htmlFor="company" className="control-label">
                  Shop Name
                </Form.Label>
                <Form.Control
                  id="company"
                  required
                  name="company"
                  type="text"
                  className={clsx(
                    'form-control',
                    hasError && !formData.company && 'is-invalid'
                  )}
                  placeholder="Enter name of shop"
                  value={formData.company}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="form-group required mb-3">
                <Form.Label htmlFor="shop_type" className="control-label">
                  Shop Type
                </Form.Label>
                <Select
                  id="shop_type"
                  name="shop_type"
                  className={clsx(
                    'form-control select capitalize',
                    hasError && formData.shop_type === 'none' && 'is-invalid'
                  )}
                  disableUnderline
                  value={formData.shop_type}
                  onChange={handleChange}
                >
                  <MenuItem value="none" disabled>
                    Select Shop Type
                  </MenuItem>
                  {shopTypes.map((type, index) => (
                    <MenuItem
                      value={type}
                      key={`item-${index + 1}`}
                      className="capitalize"
                    >
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </Form.Group>
              <CallToAction
                variant="primary"
                className="w-100 mb-4"
                size="xl"
                value="Send Report"
                customHandleClick={e => handleGetStarted(e)}
              />
              <p className="notice">
                This Shop Business Valuation Calculator is provided for
                informational purposes only, and is not intended to and does not
                replace professional financial guidance. Please consult a
                financial professional.
              </p>
            </form>
          </div>
        </div>
        <Script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=places`}
          onLoad={initPlacesAutocomplete}
        />
      </ModalBody>
    </Modal>
  );
};

export default SubmitModal;
